.section-clients {
    .clients {
        display: flex;
        flex-flow: row;
        flex-flow: wrap;
        .clients__item {
            text-align: center;
            padding: 25px 0;
            flex: 0 0 33.333333%;
            @include media-breakpoint-up(md) {
                flex: 0 0 25%;
            }
            @include media-breakpoint-up(lg) {
                flex: 0 0 16.666666667%;
            }
        
        }
    }
}