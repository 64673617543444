.projects {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;

    &__item {
        position: relative;
        z-index: 1;
        transform-style: preserve-3d;
        min-height: 140px;
        &:hover {
            z-index: 100;
        }
        //Gradient black opacity
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(0deg, rgba(25, 25, 29, .8) 0%, rgba(25, 25, 29, 0) 60%);
        }

        .description {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            overflow: hidden;
            display: flex;
            flex-flow: column;
            transform: translateZ(30px);
            .btn-link {
                margin-bottom: 5px;
                padding-left: 10%;
                font-size: 22px;
                margin-top: auto;
                color: #FFF;
                font-family: $font-custom-name;
                font-weight: 600;
                text-decoration: none;
                @include text-shadow;
                @include trans;

                &:after {
                    @include linkIcon;
                    @include trans;
                }

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                }
            }

            .description_badges {
                opacity: 1;
                transition: max-height 1.5s ease-out;
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;
                padding-left: 10%;
                padding-right: 5%;
                padding-bottom: 10%;
                .badge {
                    margin-right: 5px;
                }
            }
        }


        @include media-breakpoint-between(xs,sm) {
            flex: 0 0 50%;
            overflow: hidden;

            img {
                width: 100%;
                object-fit: cover;
                width: auto;
            }

            .description .btn-link {
                font-size: 20px;
            }
        }

        @media (max-width: 400px) {
            .description .btn-link {
                font-size: 14px;
            }
        }

        @include media-breakpoint-only(md) {
            flex: 0 0 33.3333333333%;

            .description .btn-link {
                font-size: 22px;
            }
        }

        @include media-breakpoint-only(lg) {
            flex: 0 0 25%;

            .description .btn-link {
                font-size: 23px;
            }
        }

        @include media-breakpoint-only(xl) {
            flex: 0 0 20%;

            .description .btn-link {
                font-size: 24px;
            }
        }

        @include media-breakpoint-up(xxl) {
            flex: 0 0 16.6666666667%;

            .description .btn-link {
                font-size: 32px;
            }
        }

        @include media-breakpoint-up(xl) {
            .description {
                .btn-link {
                    &:after {
                        opacity: 0;
                    }

                    &:hover:after {
                        opacity: 1;
                    }
                }

                .description_badges {
                    max-height: 0;

                    .badge {
                        opacity: 0;

                        @for $i from 0 through 8 {
                            &:nth-child(#{$i}) {
                                transition-delay: #{$i * .2}s;
                            }
                        }
                    }
                }
            }

            &:hover {
                .description {
                    .description_badges {
                        opacity: 1;
                        max-height: 300px;
                        transition: max-height .85s ease-in;

                        .badge {
                            opacity: 1;
                        }
                    }
                }
            }

        }
    }
}