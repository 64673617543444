
.badge {
    color: #FFF;
    font-size: 13px;
    padding:  6px 8px 3px 8px;
    line-height: 12px;
    border-radius: 5px;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 1px;
    opacity: 1;
    font-weight: 400;
    margin-bottom: 5px;
    font-family: $font-custom-name;
    @include trans;
    &.badge_htmlcss {
        background-color: #105590;
        
    }
    &.badge_design {
        background-color: #d93212;
       
    }
    &.badge_ux {
        background-color: #bd2206;
        
    }
    &.badge_responsive {
        background-color: #328b0b;
      
    }
    &.badge_js {
        background-color: #0f7077;
       
    }
    &.badge_wordpress {
        background-color: #1e76bc;
    }
    color: rgba(255,255,255,0.85);
}