body {
    overflow-x: hidden;
}
.narrow-text {
    // max-width: 800px;

    p {
        font-size: 1.1rem;
    }
    @include media-breakpoint-down(sm){
        padding-left: 15px;
        padding-right: 15px;
    }
    @include media-breakpoint-up(xl){
        font-size: 2rem;
    }
}
.email {
    @include emailIcon;
    margin-right: 20px;
}
.mobile {
    @include mobileIcon;
    white-space: nowrap;
}