@mixin trans {
    transition: all 0.25s ease-in;
}


@mixin text-shadow {
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}


@mixin linkIcon {
    content: '';
    display: inline-block;
    width: 11px;
    height: 11px;
    line-height: 22px;
    background: url("/api/public/images/sprite.svg") 0 0 no-repeat;
    vertical-align: middle;
    margin-left: 10px;
    margin-top: -5px;
}
@mixin emailIcon {
    &:before {
        content: '';
        display: inline-block;
        width: 18px;
        height: 14px;
        line-height: 22px;
        background: url("/api/public/images/sprite.svg") -20px 0 no-repeat;
        vertical-align: middle;
        margin-right: 10px;
        margin-top: -4px;
    }
}
@mixin mobileIcon {
    &:before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        line-height: 22px;
        background: url("/api/public/images/sprite.svg") -40px 0 no-repeat;
        vertical-align: middle;
        margin-right: 10px;
        margin-top: -4px;
    }
}
