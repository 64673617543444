$color-primary:         #0099ff;
$color-secondary:       #a87a4f;

$color-bg:              #f6f9fc;
$color-bg-hero:         #082050;

$preview-tab-color:     #e1e8f0;
$preview-icon-bg:       #c4cfdc;

/* BOOTSTRAP GLOBAL  */
$body-bg:               #f5f5f5;
$body-color:            #7a808a;
$border-radius:         8px;
$grid-gutter-width:     15px !default;


/* FONTS BASIC */
$font-family-sans-serif: 'Playfair Display', cursive;
$font-size-base:        1rem;

/* FONTS HEADINGS */
$headings-color: #191c23; 
$headings-font-family: 'Abril Fatface', cursive;

/* FONTS CUSTOM */
$font-custom-name: 'Teko', sans-serif;

/* GRID */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1440px
) !default;

/* BOOTSTRAP COLORS */
$theme-colors: (
  "primary":    #0099ff,
  "secondary":  #a87a4f,
  "success":    #50ae10,
  "info":       #a8ddf0,
  "warning":    #ffcc66,
  "danger":     red,
  "light":      #b6b6b6,
  "dark":       #66758b,
);

/*BUTTONS ****************************************/

// // Button LG

$input-btn-padding-y:   1.5*.375rem !default;
$input-btn-padding-x:   3*.75rem !default;


// //NAVBAR ********************************************************
// $navbar-padding-y: 0;
// $navbar-padding-x: 0;

// //Nav link ********************************************************
// $nav-link-padding-y: .5rem;
// $nav-link-padding-x: 1rem;

// //Navbar Expanded >$lg
// $navbar-nav-link-padding-x: 1.5rem;

// //.navbar-brand
// $navbar-brand-padding-x: 0;
// $navbar-brand-padding-y: 15px;

// //DROPDOWN
// $dropdown-border-radius: 0;
// $dropdown-border-width: 0;
// $dropdown-spacer: 0;

// //FORMS ********************************************************
// $input-padding-y: 8px;
// $input-border-color: #e9e9e9;
// //Focus
// //$input-focus-box-shadow: none;
// $input-btn-focus-width: 2px;
// //$input-btn-focus-color

// //ALERTS ********************************************************
// $alert-margin-bottom: $grid-gutter-width;

// //.custom-select
// $custom-select-padding-y: 6px;

// // BADGES ********************************************************



// //CUSTOM Checbox
// // $custom-control-indicator-size: 20px;
// // $custom-control-indicator-bg: #FFF;
// // $custom-checkbox-indicator-icon-checked: url('../../../gfx/sprite_base.svg');
// // $custom-radio-indicator-icon-checked: url('../../../gfx/sprite_base.svg');
// // $custom-select-indicator: url('../../../gfx/sprite_custom_select.svg');

// //Pagination
// $pagination-border-width: 1px;
// $pagination-border-color: #e2e6e9;
// $pagination-padding-y: 5px; 
// $pagination-padding-x: 10px;


// //Modals
// $modal-header-border-color: #e9e9e9;
// $modal-header-padding: 20px;
// $modal-inner-padding: 20px; 
// $modal-lg: 950px;
// $modal-md: 750px;


// //NAV TABS
// $nav-tabs-link-active-border-color: transparent;

// //BREADCRUMB
// $breadcrumb-bg: transparent;
// $breadcrumb-padding-y: 5px;
// $breadcrumb-padding-x: 0;
// $breadcrumb-margin-bottom: 15px;
// $breadcrumb-border-radius: 0;
// $breadcrumb-divider: "";
// $breadcrumb-active-color: #153655;

// //ALERT
// $alert-padding-y: 1rem;

// //Tooltip
// $tooltip-bg: #727272;
// $tooltip-color: #FFF;
// $tooltip-padding-y: 5px;
// $tooltip-padding-x: 20px;
// $tooltip-font-size: 11px;