.section {
    &.section-welcome,
    &.section-contact {
        padding: 10% 0;
    }

    &.section-clients {
        background: #FFF;
        padding: 10% 0;
    }

    &.section-quotes {
        background: #FFF;
        padding: 0;
    }
}

.swiper-container-work {
    .swiper-slide {

    }
}