.quotes {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    &__image {
        img {
            width: 100%;
            height: auto;
        }
    }
    &__item {
        background: #FFF;
        position: relative;
        //margin: 1px 1px 0 0;
        
        .wrapper {
            border: 1px solid #e4e9f1;
            padding: 50px;
        }
        .text {
            font-size: 17px;
            margin-bottom: 20px;
            color: $headings-color;
        }
        .author {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 2px;
        }
        .company {
            font-size: 14px;
        }
        
        @include media-breakpoint-between(xs,sm) {
            flex: 0 0 100%;
            &:nth-child(2n+1) {
                background: #eef1f6;
            }
        }
        @include media-breakpoint-only(md) {
            flex: 0 0 50%;
            @each $offset in 1 4 {
                &:nth-child(4n + #{$offset}) {
                  background: #eef1f6;
                }
            }
        }
        @include media-breakpoint-only(lg) {
            flex: 0 0 33.333333333%;
            @each $offset in 1 3 5 7 {
                &:nth-child(6n + #{$offset}) {
                  background: #eef1f6;
                }
            }
        }
        @include media-breakpoint-down(lg){
            .wrapper {
                height: 100%;
                width: 100%;
                padding: 30px 40px;
                border: 0;
            }
        }
        @include media-breakpoint-up(xl){
            height: 0;
            .wrapper {
                position: absolute;
                left: 10px;
                right: 10px;
                top: 10px;
                bottom: 10px;
                display: flex;
                flex-flow: column;
                div:first-child {
                    margin-top: auto;
                }
            }
        }        
        @include media-breakpoint-only(xl) {
            flex: 0 0 25%;
            padding-top: 25%;
            @each $offset in 2 4 5 7   {
                &:nth-child(8n + #{$offset}) {
                  background: #eef1f6;
                }
            }
        }
        @include media-breakpoint-up(xxl) {
            flex: 0 0 20%;
            padding-top: 20%;
            background: #FFF;
            @each $offset in 1 3 5 7 9 {
                &:nth-child(10n + #{$offset}) {
                  background: #eef1f6;
                }
            }
        }
    }
}